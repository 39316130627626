import React, {Component} from 'react';
import Logo from './logo.svg';

class App extends Component {
  render() {
    return (
      <div className="app">
          <img src={Logo} className="logo" alt="Aidon Hudson"/>
      </div>
    );
  }
}

export default App;
